import React from 'react';
import axios from 'axios';
import { Table } from 'antd';

import localStorage from '../../../../utils/localStorage';
import { getTranslationCache } from '../../../../selectors/language';
import { StyledTable, lightTheme } from '../style';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { columns } from './mock';
import '../style.css';
class Organisation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      columns,
      datasource: [],
      translationCache: props.translationCache || []
    };
  }

  componentDidMount() {
    this._getOrganisationList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }
  _getOrganisationList = () => {
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: '/api/organisation/get',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then(({ data }) => {
        this.setState({ datasource: data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] && translationCache[keyword]
      ? translationCache[keyword]
      : keyword;
  };

  render() {
    let { datasource } = this.state;
    const columns = [
      {
        title: this.translation('Id'),
        dataIndex: 'Id'
      },
      {
        title: this.translation('Organisation Name'),
        dataIndex: 'Name'
      },
      {
        title: this.translation('Short Name'),
        dataIndex: 'ShortName'
      },
      {
        title: this.translation('Description'),
        dataIndex: 'Description'
      },
      {
        title: this.translation('Tag Line'),
        dataIndex: 'TagLine'
      },
      {
        title: this.translation('URL'),
        dataIndex: 'URL'
      }
    ];
    return (
      <StyledTable theme={lightTheme}>
        <Table
          className="basictable"
          columns={columns}
          dataSource={datasource}
          onRow={() => {
            return {
              onClick: () => {
                this.props.history.push({
                  pathname: `/rubus/settingsPage/UserManagement/Site`
                });
              }
            };
          }}
          pagination={false}
        />
      </StyledTable>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(Organisation);
