import localStorage from '../../utils/localStorage';

export default class layout {
  static getUserprofile() {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    return fetch(`/api/auth/me?SiteId=${siteId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }
}
