import axios from 'axios';

import localStorage from '../../../../../utils/localStorage';

export default class menuAdapter {
  static firstMenuAdapter(menuHierarchy) {
    return menuHierarchy && Object.keys(menuHierarchy).map((i) => menuHierarchy[i]);
  }

  static async secoundMenuAdapter(menuHierarchy, parentName, translationCache) {
    let secondLevelMenu = [];
    if (parentName && menuHierarchy[parentName]) {
      let children = menuHierarchy[parentName].children;
      if (children) {
        secondLevelMenu = Object.keys(children).map((i) => children[i]);
      }
    }

    secondLevelMenu.map((menuItem, key) => {
      if (translationCache && translationCache[menuItem.name] !== undefined) {
        if (translationCache[menuItem.name]) {
          secondLevelMenu[key].name = translationCache[menuItem.name];
        }
      }
      return {};
    });

    return secondLevelMenu;
  }

  static setFirstDefaultPages(menuHierarchy, selectedMenu, selectedItem) {
    selectedMenu.push(selectedItem.key); //First level
    if (
      menuHierarchy &&
      menuHierarchy[selectedItem.key] &&
      Object.keys(menuHierarchy[selectedItem.key].children).length !== 0
    ) {
      let secondLevelDefaultKey = '';
      let status = false;
      Object.keys(menuHierarchy[selectedItem.key].children).map((secondMenuItems) => {
        if (status === false) {
          selectedMenu.push(secondMenuItems); //second level
          secondLevelDefaultKey = secondMenuItems;
          status = true;
        }
        return {};
      });

      // let secondLevelDefaultKey = Object.keys(menuHierarchy[selectedItem.key].children)[0]
      // selectedMenu.push(secondLevelDefaultKey)//second level
      if (secondLevelDefaultKey) {
        if (
          menuHierarchy[selectedItem.key].children[secondLevelDefaultKey].children !== undefined &&
          menuHierarchy[selectedItem.key].children[secondLevelDefaultKey].hideInMenu === false
        ) {
          let thirdLevel = menuHierarchy[selectedItem.key].children[secondLevelDefaultKey].children;
          if (Object.keys(thirdLevel).length !== 0) {
            //Checking weather exist or not
            let thirdLevelDefaultKey = Object.keys(thirdLevel)[0];
            selectedMenu.push(thirdLevelDefaultKey); //third level
          }
        }
      }
    }
    return selectedMenu;
  }

  static setSecondDefaultPages(menu, previousMenu, secondLevelMenuSelected) {
    previousMenu.length = 2;
    let selectedMenuObject =
      menu[previousMenu[0]] && menu[previousMenu[0]].children[secondLevelMenuSelected.key];
    previousMenu[1] = secondLevelMenuSelected.key;
    if (selectedMenuObject) {
      if (
        selectedMenuObject.children !== undefined &&
        Object.entries(selectedMenuObject.children).length !== 0
      ) {
        let thirdLevelDefaultKey = Object.keys(selectedMenuObject.children)[0];
        previousMenu.push(thirdLevelDefaultKey);
      }
    }
    return previousMenu;
  }

  static setMenuPermissions(menuHierarchy, permissions, currentLanguage, translationCache) {
    let arrayObject = [];
    permissions.map((perm) => {
      if (Object.values(perm)[0] === 'web' || Object.values(perm)[0] === 'configuration') {
        arrayObject.push(Object.values(perm)[1]);
      } else if (Object.values(perm)[1] === 'read') {
        arrayObject.push(Object.values(perm)[0]);
      }
      return {};
    });
    return this.children(menuHierarchy, arrayObject, currentLanguage, translationCache);
  }

  static children(menu, permissions) {
    Object.keys(menu).map((menuObject) => {
      if (permissions.includes(menuObject)) {
        menu[menuObject].hideInMenu = false;
      }
      // else{
      //   menu[menuObject].hideInMenu = false
      // }
      if (menu[menuObject].children !== undefined) {
        if (Object.entries(menu[menuObject].children).length !== 0) {
          menu[menuObject].children = this.children(menu[menuObject].children, permissions);
        }
      }
      return {};
    });
    return menu;
  }

  static async setBackendMenuItemsAPI(menuHierarchy, parentName) {
    if (menuHierarchy[parentName].api) {
      const siteId = localStorage.get('currentSite');
      const accessToken = localStorage.get('accessToken');
      const Object = {
        method: 'GET',
        url: `/api/page/get?SiteId=${siteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };
      let secondLevelMenuResult = await axios(Object).then((response) => {
        let apiResult = response.data;
        let secondLevelMenu = {};

        apiResult.map((eachObject) => {
          secondLevelMenu[eachObject.name] = {
            name: eachObject.name,
            path: `${menuHierarchy[parentName].path}/${eachObject.name}`,
            key: eachObject.name,
            icon: eachObject.name,
            hideInMenu: false,
            children: {}
          };
          return {};
        });

        return secondLevelMenu;
      });
      return secondLevelMenuResult;
    }
  }

  static setBackendMenuItems(menuHierarchy) {
    Object.keys(menuHierarchy).map(async (menu) => {
      if (menuHierarchy[menu].api) {
        let children = await this.setBackendMenuItemsAPI(menuHierarchy, menuHierarchy[menu].key);
        menuHierarchy[menu].children = children;
      }
    });
    return menuHierarchy;
  }
}
