import React from 'react';
import ImgCrop from 'antd-img-crop';
import { Upload, message, Form, InputNumber, Select } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { getTranslationCache } from '../../../selectors/language';
import { getUserProfile } from '../../../selectors/layout';
import { constant } from './constants';
import localStorage from '../../../utils/localStorage';

const { Option } = Select;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class LogoUploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      siteId: '',
      width: 1000,
      height: 250,
      translationCache: props.translationCache || [],
      userProfile: props && props.userProfile ? props.userProfile : {},
      siteList: []
    };
  }

  componentDidMount() {
    this._getsiteList(this.props.userProfile);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.userProfile !== prevProps.userProfile) {
      this._getsiteList(this.props.userProfile);
    }
  }

  beforeUpload = (file) => {
    let { siteId } = this.state;
    if (siteId) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPG) {
        message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJPG && isLt2M;
    } else {
      message.warn('Please select Site');
    }
  };

  _getsiteList = (userProfile) => {
    let siteList =
      this.props && userProfile && userProfile.belongsTo && userProfile.belongsTo.sites
        ? userProfile.belongsTo.sites
        : null;

    this.setState({ siteList });
  };

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      // return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false
        })
      );
    }
  };

  setParameter = (parameter, value) => {
    if (parameter === 'width') {
      this.setState({
        width: value
      });
    } else if (parameter === 'height') {
      this.setState({
        height: value
      });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onSiteChange = (siteId) => {
    this.setState({
      siteId
    });
  };

  render() {
    const { imageUrl, width, height, siteList, siteId } = this.state;
    const userSite = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">{this.translation('Upload')}</div>
      </div>
    );

    return (
      <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Form.Item
          label={this.translation(constant.Site)}
          name="siteId"
          rules={[{ required: true, message: 'Select Site' }]}
        >
          <Select
            placeholder={this.translation('Select Site')}
            style={{ width: '300px' }}
            onChange={this.onSiteChange}
          >
            {siteList &&
              Object.keys(siteList).map((item) => (
                <Option key={siteList[item].site.Id} value={item}>
                  {siteList[item].site.Name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={this.translation(constant.Image_Width)}
          name="width"
          rules={[{ required: true, message: 'Please input width!' }]}
        >
          <InputNumber style={{ width: '300px' }} onChange={(width) => this.setState({ width })} />
        </Form.Item>

        <Form.Item
          label={this.translation(constant.Image_Height)}
          name="height"
          rules={[{ required: true, message: 'Please input height!' }]}
        >
          <InputNumber
            style={{ width: '300px' }}
            onChange={(height) => this.setState({ height })}
          />
        </Form.Item>

        <Form.Item
          label={this.translation(constant.Upload)}
          name="upload"
          rules={[{ required: true, message: 'upload!' }]}
        >
          <span>
            <ImgCrop width={width} height={height}>
              <Upload
                name="logo"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`/api/site/logoUpload?SiteId=${siteId}&SiteOfLogo=${userSite}`}
                headers={{
                  Authorization: Authorization
                }}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
              >
                {imageUrl ? <img src={imageUrl} height="100px" alt="avatar" /> : uploadButton}
              </Upload>
            </ImgCrop>
            ({width}* {height})
          </span>
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  userProfile: getUserProfile()
});

export default connect(mapStateToProps)(LogoUploadFile);
