export default class loginAuth {
  static userAuth(user) {
    return fetch('/api/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static signUpAuth(user) {
    return fetch('/api/auth/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }
}
