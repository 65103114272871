import localStorage from '../../utils/localStorage';

export default class deviceType {
  static getDeviceTypeList() {
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/deviceType/get`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static saveDeviceType(payload) {
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/deviceType/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload)
    }).then(responseToken);
    function responseToken(response) {
      response.json();
    }
  }

  static updateDeviceType(payload) {
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/deviceType/update?Id=${payload.Id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload)
    }).then(responseToken);
    function responseToken(response) {
      response.json();
    }
  }

  static deviceParameterCURD(payload) {
    if (payload) {
      Object.keys(payload).map((parameterKey) => {
        let parameter = payload[parameterKey];
        if (parameter.Id) {
          updateDeviceParameters(parameter);
        } else {
          saveDeviceParameters(parameter);
        }
        return {};
      });
    }

    function saveDeviceParameters(payload) {
      const accessToken = localStorage.get('accessToken');
      return fetch(`/api/parameter/create`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(responseToken);
      function responseToken(response) {
        return response.json();
      }
    }

    function updateDeviceParameters(payload) {
      const accessToken = localStorage.get('accessToken');
      return fetch(`/api/parameter/update?Id=${payload.Id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(responseToken);
      function responseToken(response) {
        return response.json();
      }
    }
  }

  static deleteDeviceParameter(payload) {
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/deviceType/delete?Id=${payload}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      response.json();
    }
  }

  static getDeviceTypeCMList() {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/deviceType/cache?SiteId=${siteId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static deleteDeviceType(deviceTypeId) {
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/deviceType/delete?Id=${deviceTypeId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response;
    }
  }
}
