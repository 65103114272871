import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Table, Col, Input, Drawer, Button, Card, Row, message, Popconfirm, Form } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import localStorage from '../../../../utils/localStorage';
import { DrawerFooter, StyledTable, lightTheme } from '../style';
import { getUserProfile } from '../../../../selectors/layout';
import { getTranslationCache } from '../../../../selectors/language';
import '../style.css';
import { constant } from '../../Constants';

class Site extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      datasource: [],
      editObj: {},
      translationCache: props.translationCache || []
    };
    this.SiteConfigRef = React.createRef();
  }

  componentDidMount() {
    this._getSiteListById();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  _getSiteListById = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `/api/site/get?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ datasource: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  openModal = () => {
    if (this.SiteConfigRef.current) {
      this.SiteConfigRef.current.resetFields();
    }
    this.setState({
      visible: true
    });
  };

  _createSite = () => {
    let values = this.SiteConfigRef.current.getFieldsValue('Site_form');
    delete values.Id;
    values = {
      ...values,
      OrganizationId: 1,
      ImageURL: ''
    };
    this._createSiteAPI(values);
  };

  _updateSite = (e) => {
    e.preventDefault();
    let values = this.SiteConfigRef.current.getFieldsValue('Site_form');
    this._updateSiteAPI(values);
  };

  _updateSiteAPI = (updateObject) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgUpdateHeaders = {
      method: 'PATCH',
      url: `/api/site/update?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: updateObject
    };
    axios(orgUpdateHeaders)
      .then(() => {
        message.success('Record Updated');
        this._getSiteListById();
        this.setState({
          visible: false,
          editObj: {}
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _createSiteAPI = (data) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgCreateHeaders = {
      method: 'POST',
      url: `/api/site/create?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    axios(orgCreateHeaders)
      .then(() => {
        this._getSiteListById();
        this.setState({ visible: false });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  onFinishFailed = () => {
    message.error('please enter required fields');
  };

  onClose = () => {
    if (this.SiteConfigRef.current) {
      this.SiteConfigRef.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {}
    });
  };

  editSite = (editObj) => {
    if (this.SiteConfigRef.current) {
      this.SiteConfigRef.current.setFieldsValue(editObj);
    }
    this.setState({
      editObj,
      visible: true
    });
  };

  getRoles = (siteId) => {
    this.props.history.push({
      pathname: `/rubus/settingsPage/UserManagement/${siteId}/Roles`
    });
  };

  _deleteSite = (record) => {
    const accessToken = localStorage.get('accessToken');
    let orgDeleteHeaders = {
      method: 'DELETE',
      url: `/api/site/delete?Id=${record.Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        SiteId: record.SiteId,
        Id: record.Id
      }
    };
    axios(orgDeleteHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          message.success('Site Deleted');
          this._getSiteListById(1);
          this.setState({ visible: false });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { datasource, visible, editObj } = this.state;
    const siteId = localStorage.get('currentSite');

    const columns = [
      {
        title: this.translation('Name'),
        dataIndex: 'Name',
        key: 'Name'
      },
      {
        title: this.translation('Code'),
        dataIndex: 'Code',
        key: 'Code'
      },
      {
        title: this.translation('Address'),
        dataIndex: 'Address',
        key: 'Address'
      },
      {
        title: this.translation('Description'),
        dataIndex: 'Description'
      },
      {
        title: this.translation('Action'),
        dataIndex: '',
        key: '',
        render: (text, record) => (
          <div>
            {siteId && siteId === '0' ? (
              <span>
                <button
                  type="button"
                  onClick={() => this.editSite(record)}
                  style={{ marginRight: '10px' }}
                  className="ant-btn"
                >
                  <EditOutlined />
                </button>
                <Popconfirm
                  title={this.translation('Are you sure delete Site ?')}
                  onConfirm={() => {
                    this._deleteSite(record);
                  }}
                  okText={this.translation(constant.yes)}
                  cancelText={this.translation(constant.no)}
                >
                  <button type="button" className="ant-btn" style={{ marginRight: '10px' }}>
                    <DeleteOutlined />
                  </button>
                </Popconfirm>
              </span>
            ) : null}
            <Button
              type="primary"
              style={{ backgroundColor: '#214972' }}
              onClick={() => this.getRoles(record.Id)}
              className="ant-btn"
            >
              {this.translation('Get Roles')}
            </Button>
          </div>
        )
      }
    ];
    return (
      <Card bordered={false}>
        <Drawer title="Create Site" visible={visible} width={600} closable onClose={this.onClose}>
          <Form
            layout="vertical"
            ref={this.SiteConfigRef}
            name="Site_form"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={editObj || {}}
            onFinishFailed={this.onFinishFailed}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Name"
                  name="Name"
                  rules={[{ required: true, message: 'Please input your Name!' }]}
                >
                  <Input placeholder="Please enter Name" />
                </Form.Item>
                <Form.Item
                  label="Id"
                  display="none"
                  name="Id"
                  hidden={true}
                  initialValue={editObj.Id || ''}
                >
                  <Input style={{ display: 'none' }} />
                </Form.Item>
                <Form.Item
                  label="Code"
                  name="Code"
                  rules={[{ required: true, message: 'Please input your Code!' }]}
                >
                  <Input placeholder="Please enter Code" />
                </Form.Item>
                <Form.Item
                  label="Geo Location"
                  name="Location"
                  rules={[{ required: true, message: 'Please input Geo Location!' }]}
                >
                  <Input placeholder="Please enter Geo Location" />
                </Form.Item>
                <Form.Item
                  label="Address"
                  name="Address"
                  rules={[{ required: true, message: 'Please input Address!' }]}
                >
                  <Input placeholder="Please enter Address" />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name="Description"
                  rules={[{ required: true, message: 'Please input Description!' }]}
                >
                  <Input placeholder="Please enter Description" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            {!editObj.Id ? (
              <Button type="primary" onClick={(e) => this._createSite(e)}>
                Create
              </Button>
            ) : (
              <Button type="primary" onClick={this._updateSite}>
                Update
              </Button>
            )}
          </DrawerFooter>
        </Drawer>

        {siteId && siteId === '0' ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0px' }}>
            <Button type="primary" onClick={this.openModal}>
              <PlusOutlined /> Add Site
            </Button>
          </div>
        ) : null}

        <StyledTable theme={lightTheme}>
          <Table
            className="basictable"
            columns={columns}
            dataSource={datasource}
            pagination={
              datasource.length > 10
                ? {
                    pageSize: '10'
                  }
                : false
            }
          />
        </StyledTable>
      </Card>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  userProfile: getUserProfile()
});
export default connect(mapStateToProps)(Site);
