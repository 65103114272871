export default class rolesPermissions {
  static menuAdapter(menu) {
    let menuObject = Object.keys(menu);
    let finalObject = menuObject.map((menus) => {
      let obj = {};
      obj['title'] = menu[menus].title;
      obj['key'] = menus;
      obj['permissions'] = Object.keys(menu[menus].permissions).map((perm) => {
        return {
          label: menu[menus].permissions[perm],
          value: perm
        };
      });
      return obj;
    });
    return finalObject;
  }

  static convertJson(rolesList, membersList) {
    membersList.forEach((member, index) => {
      rolesList.forEach((role) => {
        if (member.role === role._key) {
          membersList[index].role = role.role;
        }
      });
    });
    return membersList;
  }

  static menuListAdapter(menuObject) {
    if (menuObject && menuObject.children) {
      let finalObject = [];
      Object.keys(menuObject.children).map((menuItem) => {
        let menu = menuObject.children[menuItem];
        if (menu.children) {
          let objects = {
            ...menu,
            title: menu.name,
            children: Object.keys(menu.children).map((menus) => {
              return this.setChildMenuItem(menu.children[menus]);
            })
          };
          finalObject.push(objects);
        } else {
          finalObject.push({
            ...menu,
            title: menu.name
          });
        }
        return {};
      });
      return finalObject;
    }
  }

  static setChildMenuItem(menuItem) {
    let children = [];
    if (menuItem.children) {
      children = {
        ...menuItem,
        title: menuItem.name,
        children: Object.keys(menuItem.children).map((menu) => {
          return this.setChildMenuItem(menuItem.children[menu]);
        })
      };
    } else {
      children = {
        ...menuItem,
        title: menuItem.name
      };
    }
    return children;
  }

  static generateNestChildren(mainObject, checkedKey) {
    let childrenObject = {};
    if (mainObject && mainObject.children) {
      let mainJSONObject = mainObject.children;
      childrenObject = {};
      Object.keys(mainJSONObject).map((moduleKey) => {
        if (checkedKey.includes(moduleKey)) {
          childrenObject[moduleKey] = mainObject.children[moduleKey];
        } else {
          if (
            mainObject.children[moduleKey] &&
            mainObject.children[moduleKey].children &&
            Object.keys(mainObject.children[moduleKey].children).length > 0
          ) {
            let response = this.generateNestChildren(mainObject.children[moduleKey], checkedKey);
            if (response) {
              childrenObject[moduleKey] = response;
            }
          }
        }
        return {};
      });
    }

    if (childrenObject && Object.keys(childrenObject).length > 0) {
      return {
        ...mainObject,
        path: childrenObject[Object.keys(childrenObject)[0]].path,
        children: childrenObject
      };
    } else {
      return null;
    }
  }

  static createConfiguration = (mainObject, checkedKey) => {
    let finalGeneratedObject = {};
    let finalObject = {};
    if (mainObject && mainObject.children && checkedKey && Array.isArray(checkedKey)) {
      let mainJSONObject = mainObject.children;
      finalObject = {};
      Object.keys(mainJSONObject).map((moduleKey) => {
        if (checkedKey.includes(moduleKey)) {
          finalObject[moduleKey] = mainObject.children[moduleKey];
        } else {
          if (
            mainObject.children[moduleKey] &&
            mainObject.children[moduleKey].children &&
            Object.keys(mainObject.children[moduleKey].children).length > 0
          ) {
            let response = this.generateNestChildren(mainObject.children[moduleKey], checkedKey);
            if (response) {
              finalObject[moduleKey] = response;
            }
          }
        }
        return {};
      });
    }
    finalGeneratedObject = {
      ...mainObject,
      path:
        finalObject[Object.keys(finalObject)[0]] && finalObject[Object.keys(finalObject)[0]].path
          ? finalObject[Object.keys(finalObject)[0]].path
          : '',
      children: finalObject
    };
    return finalGeneratedObject;
  };

  static permissionAdapter(permissionList) {
    if (permissionList) {
      let finalObject = {
        asset_device: [],
        dashboard: [],
        timeScale: [],
        elog_book: [],
        reports: [],
        hmi: [],
        trends: [],
        rule_engine: []
      };
      let keysList = [
        'asset_device',
        'dashboard',
        'timeScale',
        'elog_book',
        'reports',
        'hmi',
        'trends',
        'rule_engine',
        'user_management'
      ];
      finalObject = keysList.map((permission) => {
        let listData = permissionList.map((permissionObject) => {
          return permissionObject['resource'] === permission ? permissionObject['action'] : null;
        });
        return { [permission]: listData.filter((n) => n) };
      });
      finalObject = finalObject.reduce((value, result) => {
        return { ...value, ...result };
      });
      return finalObject;
    }
  }

  static createPermissions(checkedPermissionsKeys) {
    if (checkedPermissionsKeys) {
      let finalObject = [];
      Object.keys(checkedPermissionsKeys).map((resource) => {
        if (checkedPermissionsKeys[resource]) {
          checkedPermissionsKeys[resource].map((action) => {
            let obj = {
              resource,
              action
            };
            finalObject.push(obj);
            return {};
          });
        }
        return {};
      });
      return finalObject;
    }
  }

  static generateCheckedList(configuration) {
    let keys = [];
    if (configuration) {
      Object.keys(configuration).map((config) => {
        if (configuration[config].children) {
          Object.keys(configuration[config].children).map((conf) => {
            let child = this.generateChildCheckedList(configuration[config].children[conf]);
            if (child && Array.isArray(child)) {
              keys.push.apply(keys, child);
            } else {
              keys.push(child);
            }
            return {};
          });
        } else {
          keys.push(config);
        }
        return {};
      });
    }
    return keys;
  }

  static generateChildCheckedList(module) {
    if (module) {
      if (module.children) {
        return Object.keys(module.children).map((modItem) => {
          let child = this.generateChildCheckedList(module.children[modItem]);
          return child;
        });
      } else {
        return module.key;
      }
    }
  }
}
