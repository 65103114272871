import React, { Component } from 'react';

import { StyledFooter, FooterAlignment, FooterCopyRight } from './styles';
import '../styles.css';

class FooterLayout extends Component {
  render() {
    return (
      <div>
        <StyledFooter style={{ padding: '0px', backgroundColor: '#c3c3c3' }}>
          <FooterAlignment>
            <FooterCopyRight>
              <span>© 2023 Rubus Digital Inc.</span>
            </FooterCopyRight>
          </FooterAlignment>
        </StyledFooter>
      </div>
    );
  }
}

export default FooterLayout;
