import localStorage from '../../utils/localStorage';

export default class assetCache {
  static asset() {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/asset/cache?SiteId=${siteId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static device() {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/device/cache?SiteId=${siteId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static createAsset(payload) {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/asset/create?SiteId=${siteId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload)
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static updateAsset(payload) {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/asset/update/${payload._key}?SiteId=${siteId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload)
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static deleteAsset(payload) {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/asset/delete/${payload}?SiteId=${siteId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }
}
