import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Form, Input, Drawer, Button, Card, message, Popconfirm, Select } from 'antd';

import { constant } from '../../../Settings/Constants';
import localStorage from '../../../../utils/localStorage';
import { DrawerFooter, StyledTable, lightTheme } from '../style';
import { getTranslationCache } from '../../../../selectors/language';

import '../style.css';
const { Option } = Select;

class Department extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      datasource: [],
      editObj: {},
      translationCache: props.translationCache || []
    };
  }
  departmentRef = React.createRef();

  componentDidMount() {
    this._getDepartmentList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  _getDepartmentList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `/api/department/get?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({ datasource: data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  openModal = () => {
    if (this.departmentRef.current) {
      this.departmentRef.current.resetFields();
    }
    this.setState({
      visible: true,
      editObj: {}
    });
  };

  _cancel = () => {
    if (this.departmentRef.current) {
      this.departmentRef.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {}
    });
  };

  editDepartment = (editObj) => {
    if (this.departmentRef.current) {
      this.departmentRef.current.setFieldsValue(editObj);
    }
    this.setState({
      editObj,
      visible: true
    });
  };

  _createDepartment = (e) => {
    e.preventDefault();
    this.departmentRef.current
      .validateFields()
      .then(async (values) => {
        delete values._key;
        this._createDepartmentAPI(values);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _updateDepartment = (e) => {
    const { editObj } = this.state;
    e.preventDefault();
    this.departmentRef.current
      .validateFields()
      .then(async (values) => {
        values['Id'] = editObj['Id'];
        this._updateDepartmentAPI(values);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _deleteDepartment = (Id) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgDeleteHeaders = {
      method: 'DELETE',
      url: `/api/department/delete?SiteId=${siteId}&Id=${Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgDeleteHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          message.success('Department Deleted');
          this._getDepartmentList();
          this.setState({ visible: false });
          if (this.departmentRef.current) {
            this.departmentRef.current.resetFields();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _createDepartmentAPI = (createObject) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgCreateHeaders = {
      method: 'POST',
      url: `/api/department/create?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: createObject
    };
    axios(orgCreateHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          message.success('Department Saved');
          this._getDepartmentList();
          this.setState({ visible: false });
          if (this.departmentRef.current) {
            this.departmentRef.current.resetFields();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _updateDepartmentAPI = (updateObject) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgUpdateHeaders = {
      method: 'POST',
      url: `/api/department/update?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: updateObject
    };
    axios(orgUpdateHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          message.success('Department Updated');
          this._getDepartmentList();
          this.setState({ visible: false, editObj: {} });
          if (this.departmentRef.current) {
            this.departmentRef.current.resetFields();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  renderFormSwitch(renderFormItems) {
    let { disableOnUpdate } = renderFormItems;
    switch (renderFormItems.Widget) {
      case 'Select':
        return (
          <Select
            style={{ width: 350 }}
            placeholder={`Select ${renderFormItems.Key}`}
            onChange={(e) =>
              this.departmentRef.current.setFieldsValue({ [renderFormItems.Key]: e })
            }
          >
            {renderFormItems.Options &&
              renderFormItems.Options.map((option, index) => {
                if (
                  renderFormItems.OptionsSettings &&
                  renderFormItems.OptionsSettings.key &&
                  renderFormItems.OptionsSettings.value
                ) {
                  return (
                    <Option key={index} value={option[renderFormItems.OptionsSettings.value]}>
                      {option[renderFormItems.OptionsSettings.key]}
                    </Option>
                  );
                }
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
          </Select>
        );

      default:
        return (
          <Input
            style={{ width: 350 }}
            placeholder={this.translation(`Enter ${renderFormItems.Key}`)}
            disabled={disableOnUpdate && disableOnUpdate === true ? true : false}
          />
        );
    }
  }

  onFinishFailed = () => {
    message.error('please enter required fields');
  };
  render() {
    let { datasource, visible, editObj } = this.state;
    let columns = [
      {
        title: this.translation('Department Name'),
        dataIndex: 'Name'
      },
      {
        title: this.translation('Department Code'),
        dataIndex: 'Code'
      },
      {
        title: this.translation('Description'),
        dataIndex: 'Description'
      },
      {
        title: this.translation('Action'),
        render: (text, record) => (
          <div>
            <button
              type="button"
              onClick={() => this.editDepartment(record)}
              style={{ marginRight: '10px' }}
              className="ant-btn"
            >
              <EditOutlined />
            </button>
            <Popconfirm
              title={this.translation(constant.Are_you_sure_delete_Department)}
              onConfirm={(e) => {
                e.stopPropagation();
                this._deleteDepartment(record.Id);
              }}
              okText={this.translation(constant.yes)}
              cancelText={this.translation(constant.no)}
            >
              <button type="button" className="ant-btn">
                <DeleteOutlined />
              </button>
            </Popconfirm>
          </div>
        )
      }
    ];
    let formStructure = [
      {
        DisplayName: 'Name',
        Key: 'Name',
        Widget: 'Input'
      },
      {
        DisplayName: 'Code',
        Key: 'Code',
        Widget: 'Input'
      },
      {
        DisplayName: 'Description',
        Key: 'Description',
        Widget: 'Input'
      }
    ];

    return (
      <Card bordered={false}>
        <Drawer
          title={
            !editObj.Id
              ? this.translation(constant.Create_Department)
              : this.translation(constant.Update_Department)
          }
          visible={visible}
          width={600}
          closable
          onClose={this._cancel}
        >
          <Form
            layout="vertical"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            ref={this.departmentRef}
            name="user_form"
            initialValues={editObj || {}}
            onFinishFailed={this.onFinishFailed}
          >
            {formStructure &&
              Array.isArray(formStructure) &&
              formStructure.map((formItem, index) => {
                return (
                  <Form.Item
                    key={index}
                    name={formItem.Key}
                    label={this.translation(formItem.DisplayName)}
                  >
                    {this.renderFormSwitch(formItem)}
                  </Form.Item>
                );
              })}
          </Form>

          <DrawerFooter>
            <Button onClick={this._cancel} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            {!editObj.Id ? (
              <Button type="primary" onClick={this._createDepartment}>
                {this.translation('Create')}
              </Button>
            ) : (
              <Button type="primary" onClick={this._updateDepartment}>
                {this.translation('Update')}
              </Button>
            )}
          </DrawerFooter>
        </Drawer>
        <Button
          type="primary"
          size={'large'}
          style={{
            marginBottom: '10px',
            backgroundColor: '#214972',
            border: 'white'
          }}
          onClick={this.openModal}
        >
          <PlusOutlined />
          {this.translation(constant.Add)}
        </Button>
        <StyledTable theme={lightTheme}>
          <Table
            className="basictable"
            columns={columns}
            dataSource={datasource}
            pagination={
              datasource.length > 10
                ? {
                    pageSize: '10'
                  }
                : false
            }
          />
        </StyledTable>
      </Card>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(Department);
