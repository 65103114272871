import localStorage from '../../utils/localStorage';
import axios from 'axios';

export default class device {
  static getDeviceList() {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/device/get?SiteId=${siteId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }
  static getList() {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const ParameterTypeObject = {
      method: 'POST',
      url: `/api/masterconfiguration/master?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        MasterName: 'AssetDevice_TagReference',
        ActionType: 'read'
      }
    };
    return axios(ParameterTypeObject)
      .then((response) => {
        return response.data;
      })
      .catch(function () {});
  }

  static getDeviceCMList() {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/deviceType/cmdevices?SiteId=${siteId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static createDevice(payload) {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/device/create?SiteId=${siteId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload)
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static updateDevice(payload) {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/device/update/${payload._key}?SiteId=${siteId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static deleteDeviceSaga(payload) {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`/api/device/delete/${payload}?SiteId=${siteId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response;
    }
  }
  static getConditionList() {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const ParameterTypeObject = {
      method: 'POST',
      url: `/api/masterconfiguration/master?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        MasterName: 'AssetDevice_Conditions',
        ActionType: 'read'
      }
    };
    return axios(ParameterTypeObject)
      .then((response) => {
        return response.data;
      })
      .catch(function () {});
  }
}
